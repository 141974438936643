import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.scss'],
})
export class TemplateComponent implements OnInit {
  @Input()
  public components: Array<any> | null;

  @Output()
  public selectedEvent = new EventEmitter<any>();

  @Output()
  public editEvent = new EventEmitter<any>();

  @Output()
  public deleteEvent = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {

    console.log("TemplateComponentContractComponent ngOnInit");
    console.log(this.components);

  }


  // viewItem(item: any){
  //   console.log("viewItem", item);    
  //   this.selectedEvent.emit(item);
  // }

  editItem(item: any){
    console.log("editItem", item.id);
    this.editEvent.emit(item.id);
  }

  deleteItem(item: any){
    console.log("deleteItem", item.id);
    this.deleteEvent.emit(item.id);
  }

}

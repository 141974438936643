import { Base } from "./base.class";
import { ExpertiseArea } from "./expertise-area.class";
import { Role } from "./role.class";
import { UserRolePermission } from "./user-role-permission.class";

export enum UserRoleTypeEnum {
    ADMIN= "admin",
    USER= "user"
}

export class User  extends Base{
    name: string;

    surname: string;

    email: string;

    phone: string;

    password?: string;

    pwd_hash: string;

    pwd_salt: string;

    active: boolean;

    verified: boolean;

    can_login_app: boolean;

    can_login_dashboard: boolean;
    
    can_enter_settings: boolean;

    // accessToken?: string;

    // refreshToken?: string;

    // role_permission: UserRolePermission;

    // role_permission_id: string;
    user_role: Role;

    user_role_id: string;

    regions: string[];

    role: UserRoleTypeEnum;

    color: string;

    // expertise_area: ExpertiseArea;

    // expertise_area_id: string;
}
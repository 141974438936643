import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-view-detail',
  templateUrl: './view-detail.component.html',
  styleUrls: ['./view-detail.component.scss'],
})
export class ViewDetailComponent implements OnInit {
  @Input()
  public data: Array<any> | null;

  @Input()
  public title: string;

  constructor() { }

  ngOnInit() {}

}

import { registerLocaleData } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { JwtHelperService } from '@auth0/angular-jwt';
import { UsersService } from '../pages/users/users.service';

import { AuthenticationService } from '../services/authentication.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private usersService: UsersService 
    ) { }

    public jwtHelper : JwtHelperService = new JwtHelperService(); 

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const user = this.authenticationService.currentUserValue;

        console.log(user)

        let userActive = false;

        if(user) userActive = await (await this.usersService.getById(user.id).toPromise()).active;

        console.log(userActive)

        if (user && userActive) {           
            return true;
        } else {
            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
            // this.router.navigate(['/login']);
            return false;
        }
        
    }
}
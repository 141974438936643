import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { TableComponent } from './components/table/table.component';
import { TemplateComponent } from './components/template/template.component';
import { ViewDetailComponent } from './components/view-detail/view-detail.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { DiscountListRowsModal } from './modal/discount-list-rows/discount-list-rows.component';
import { ProductFeesModalComponent } from './modal/product-fees-modal/product-fees-modal.component';
import { ProductPriceListsModalComponent } from './modal/product-price-lists-modal/product-price-lists-modal.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { HttpClientJsonpModule } from '@angular/common/http';
import { IonicModule } from '@ionic/angular';
import { BrowserModule } from '@angular/platform-browser';
import { QuillModule } from 'ngx-quill';
import { MaskitoModule } from '@maskito/angular';

@NgModule({  
  imports: 
  [ 
    IonicModule,
    CommonModule,
    // BrowserModule,
    FormsModule,
    FontAwesomeModule,
    NgxDatatableModule,
    GoogleMapsModule,
    QuillModule.forRoot({
      modules: {
        syntax: false
      }
    }),
    MaskitoModule
  ],
  declarations: [
    TableComponent,
    TemplateComponent,
    ViewDetailComponent,
    DiscountListRowsModal,
    ProductFeesModalComponent,
    ProductPriceListsModalComponent,
  ],
  exports: [
    IonicModule,
    CommonModule,
    // BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    TableComponent,
    TemplateComponent,
    FontAwesomeModule,
    ViewDetailComponent,
    NgxDatatableModule,
    GoogleMapsModule,
    QuillModule,
    MaskitoModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class SharedModule {}

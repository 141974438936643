import { Component, Input, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { ExpertiseArea } from '../../models/expertise-area.class';
import { PriceList } from '../../models/price_list.class';
import { Product } from '../../models/product.class';
import { ProductPriceList } from '../../models/product_price_list.class';

@Component({
  selector: 'app-product-price-lists-modal',
  templateUrl: './product-price-lists-modal.component.html',
  styleUrls: ['./product-price-lists-modal.component.scss'],
})
export class ProductPriceListsModalComponent implements OnInit {

  @Input()
  product: Product;
  @Input()
  expertise_areas: ExpertiseArea[];
  @Input()
  price_lists: PriceList[];

  product_price_lists: ProductPriceList[] = [];

  product_price_list_index: number;

  price: number;

  constructor(
    private modalCtrl: ModalController,
    private alertController: AlertController,
  ) {

  }

  ngOnInit() {
    this.product_price_lists = [];

    if (this.product.id) {

      this.price_lists.forEach(pl => {

        console.log(this.product.product_price_lists);


        let findPPL = this.product.product_price_lists.find(p => p.price_list_id == pl.id)

        console.log(findPPL);


        if (findPPL) {

          this.product_price_lists.push(findPPL)

        }
        else {

          console.log('qui');


          let newProductPriceList = new ProductPriceList();
          newProductPriceList.created_at = new Date();
          newProductPriceList.price = null;
          newProductPriceList.price_list_id = pl.id;
          newProductPriceList.price_list = pl;
          this.product_price_lists.push(newProductPriceList);

        }
      });


    }
    else {

      this.price_lists.forEach(pl => {
        let newProductPriceList = new ProductPriceList();
        newProductPriceList.created_at = new Date();
        newProductPriceList.price = null;
        newProductPriceList.price_list_id = pl.id;
        newProductPriceList.price_list = pl;
        this.product_price_lists.push(newProductPriceList);
      });

    }

    this.price = null;

    this.product_price_list_index = -1;
  }

  cancel() {
    this.modalCtrl.dismiss(null, 'cancel');
  }

  confirm() {
    this.modalCtrl.dismiss({ product_price_lists: this.product_price_lists }, 'confirm');

  }

  add() {
    let product_price_list: ProductPriceList = new ProductPriceList();

    console.log(product_price_list);


    product_price_list.price = this.price;

  }

  update(i: number) {

    this.product_price_lists[i].id = undefined;
    this.product_price_lists[i].price = this.price;
    this.product_price_lists[i].created_at = new Date();
    this.product_price_lists[i].updated_at = null;

    this.product_price_list_index = -1;
  }

  remove(i: number) {

    this.product_price_lists[i].id = undefined;

    this.product_price_lists[i].price = null;

    this.product_price_lists[i].created_at = new Date();

    this.product_price_lists[i].updated_at = null;

    this.price = null;

    this.product_price_list_index = -1;


  }

  UpdateOrDelete(i: number,) {
    this.price = this.product_price_lists[i].price;

    this.product_price_list_index = i;

  }

  async showError(title, msg) {

    const alert = await this.alertController.create({
      header: title,
      message: msg,
      buttons: ['OK'],
    });

    await alert.present();

  }
}

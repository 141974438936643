import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UserRolePermission } from '../../models/user-role-permission.class';


const httpOptions = {
  headers: new HttpHeaders().set('Content-Type', 'application/json')
};

@Injectable({
  providedIn: 'root'
})
export class UserRolePermissionsService {

  constructor(private http: HttpClient) { }

  getAll() {
    console.log("getAllRoles")
    return this.http.get<UserRolePermission[]>(`${environment.apiUrl}/api/${environment.apiVersion}/user_role_permission`, httpOptions)
  }

  getById(id: string) {
    console.log("getByIdRoles")
    return this.http.get<UserRolePermission>(`${environment.apiUrl}/api/${environment.apiVersion}/user_role_permission/` + id, httpOptions)
  }

  create(body) {
    console.log("createRoles")
    return this.http.post<UserRolePermission>(`${environment.apiUrl}/api/${environment.apiVersion}/user_role_permission/`, body, httpOptions)
  }

  update(body, id: string) {
    console.log("updateRoles")
    return this.http.put<UserRolePermission>(`${environment.apiUrl}/api/${environment.apiVersion}/user_role_permission/` + id, body, httpOptions)
  }

  delete(id: string) {
    console.log("deleteRoles")
    return this.http.delete<UserRolePermission>(`${environment.apiUrl}/api/${environment.apiVersion}/user_role_permission/` + id, httpOptions)
  }

}
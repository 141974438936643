import { Component } from '@angular/core';
import { AuthenticationService } from './services/authentication.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(public authenticationService: AuthenticationService) {}

  public itemsNavbar = [
    { title: 'Vendite', url: '/home', icon: 'analytics' , open: false},
    {
      title: 'Agenti', open: false,
      children: [
        { title: 'Agenti', url: '/agents', icon: 'archive' },
        { title: 'Classifica', url: '/standing', icon: 'trash' }
      ]
    },
    {
      title: 'Ordini', open: false,
      children: [
        { title: 'Ordini', url: '/orders', icon: 'archive' },
        { title: 'Black list', url: '/blacklist', icon: 'trash' },
        { title: 'Preventivi', url: '/estimates', icon: 'trash' }
      ]
    },
    {
      title: 'Prodotti', open: false,
      children: [
        { title: 'Articoli', url: '/products', icon: 'archive' },
        { title: 'Categorie', url: '/categories', icon: 'trash' },
        { title: 'Marche', url: '/brands', icon: 'trash' },
        { title: 'Listini', url: '/productLists', icon: 'trash' },
        { title: 'Franchigie', url: '/deductibles', icon: 'trash' }
      ]
    },
    { 
      title: 'Agevolazioni fiscali', 
      url: '/tax-break', 
      icon: 'analytics' , 
      open: false
    },
    { 
      title: 'Testi documento', 
      url: '/document-text', 
      icon: 'analytics' , 
      open: false
    },
    {
      title: 'Campagne', open: false,
      children: [
        { title: 'Campagne attive', url: '/activeCampaigns', icon: 'pricetags' },
        { title: 'Campagne passate', url: '/pastCampaigns', icon: 'trash' },
        { title: 'Crea Campagna', url: '/createCampaign', icon: 'trash' }
      ]
    },
    {
      title: 'Clienti', open: false,
      children: [
        { title: 'Aziende', url: '/companies', icon: 'people-circle' },
        { title: 'Leads', url: '/leads', icon: 'trash' }
      ]
    },
    {
      title: 'Notifiche', open: false,
      children: [
        { title: 'Notifiche lette', url: '/readNotifications', icon: 'notifications' },
        { title: 'Notifiche non lette', url: '/unreadNotifications', icon: 'trash' }
      ]
    },
    {
      title: 'Impostazioni', open: false,

      children: [
        { title: 'Impostazioni Generali', url: '/settings', icon: 'archive' },
        { title: 'Utenti', url: '/users', icon: 'trash' },
        { title: 'Ruoli', url: '/roles', icon: 'warning' },
        { title: 'Condizioni di pagamento', url: '/payment_conditions', icon: 'warning' },
        { title: 'Proviggioni', url: '/home', icon: 'warning' }
      ]

    }
  ];
}

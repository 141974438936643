import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AlertController, ModalController } from '@ionic/angular';
import { ProductCategory } from '../../models/category.class';
import { Product } from '../../models/product.class';

@Component({
  selector: 'app-discount-list-rows',
  templateUrl: './discount-list-rows.component.html',
  styleUrls: ['./discount-list-rows.component.scss'],
})
export class DiscountListRowsModal implements OnInit {

  @Input()
  products: Product[];
  @Input()
  product_categories: ProductCategory[];

  @Input()
  selected_category: ProductCategory;
  @Input()
  selected_product: Product;
  @Input()
  max_discount: number;
  @Input()
  checkbox: string;
  @Input()
  update: string;

  // checkbox_category: boolean;
  // checkbox_product: boolean;

  constructor(
    private modalCtrl: ModalController,
    private alertController: AlertController,
  ) {

    // this.formGroup = this.formBuilder.group({
    //   checkbox_category: [false],
    //   checkbox_product: [false],
    //   max_discount: [''],
    // });

  }

  ngOnInit() {
    console.log(this.checkbox, this.selected_product, this.selected_category, this.max_discount)
  }

  // get f() { return this.formGroup.controls}

  cancel() {
    this.modalCtrl.dismiss(null, 'cancel');
  }

  confirm() {
    if ((this.selected_category || this.selected_product) && this.max_discount) {
      if (this.max_discount < 0 || this.max_discount > 100) {
        this.showError("Attenzione!", "Lo sconto massimo applicabile deve essere compreso tra 0 e 100")
        return;
      }
      this.modalCtrl.dismiss({update: this.update, category: this.selected_category, product: this.selected_product, max_discount: this.max_discount }, 'confirm');
    }
    else {
      this.showError("Attenzione!", "Inserire tutti i dati")
    }
  }

  // onWillPresent() {
  //   console.log(this.checkbox, this.selected_product, this.selected_category, this.max_discount)

  //   // if(this.selected_category) this.product_categories = this.product_categories.filter((x) => x.id != this.selected_category.id);
  //   // if(this.selected_product) this.products = this.products.filter((x) => x.id != this.selected_product.id);

  //   // this.checkbox_category = false;
  //   // this.checkbox_product = false;
  //   this.selected_product = null;
  //   this.selected_category = null;
  //   this.max_discount = null;
  // }

  handleChangeCategory(e) {
    console.log(e.detail.value);
    
    this.selected_category = this.product_categories.find((x) => x.id == e.detail.value);
    this.selected_product = null;
  }

  handleChangeProduct(e) {
    this.selected_product = this.products.find((x) => x.id == e.detail.value);
    this.selected_category = null;
  }

  resetCheckbox(event: any) {
    // console.log(event);

    this.checkbox = event.detail.value;

  }

  async showError(title, msg?) {

    const alert = await this.alertController.create({
      header: title,
      message: msg,
      buttons: ['OK'],
    });

    await alert.present();

  }
}

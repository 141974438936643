import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faHourglassEmpty } from '@fortawesome/free-solid-svg-icons';
import { MenuController } from '@ionic/angular';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UserRolePermissionsService } from '../../pages/user-role-permissions/user-role-permissions.service';
import { UserRoleTypeEnum } from '../../models/user.class';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {

  // public title: string;

  items = []

  // subOpen = false;


  itemsNavbar = [
    { title: 'Vendite', url: '/home', icon: 'chart-simple', open: false, hide: false },
    {
      title: 'Agenti', open: false, hide: false,
      children: [
        { title: 'Mappa Localizzazione', url: '/agents', icon: 'users' },
        { title: 'Obiettivi Commerciali', url: '/commercial-goals', icon: 'bullseye' },
        { title: 'Classifica', url: '/standing', icon: 'crown' }
      ]
    },
    {
      title: 'News', open: false, hide: false,
      children: [
        { title: 'Elenco News', url: '/news', icon: 'newspaper' },
        { title: 'Categorie News', url: '/news-categories', icon: 'list' }
      ]
    },
    {
      title: 'Ordini', open: false, hide: false,
      children: [
        { title: 'Ordini', url: '/documents/order', icon: 'file-signature' },
        // { title: 'Black list', url: '/blacklist', icon: 'trash' },
        { title: 'Preventivi', url: '/documents/quotation', icon: 'file-pen' }
      ]
    },
    {
      title: 'Prodotti', open: false, hide: false,
      children: [
        { title: 'Articoli', url: '/products', icon: 'warehouse' },
        { title: 'Canoni', url: '/fees', icon: 'list' },
        { title: 'Tabella IVA', url: '/vat', icon: 'list' },
        { title: 'Listini Prezzi', url: '/price-lists', icon: 'money-check-dollar' },
        { title: 'Categorie', url: '/categories', icon: 'list' },
        { title: 'Marche', url: '/brands', icon: 'copyright' },
        { title: 'Gruppi Accessori', url: '/product-accessories', icon: 'list' },
        { title: 'Franchigie', url: '/deductibles', icon: 'list' }
      ]
    },
    { 
      title: 'Agevolazioni fiscali', 
      url: '/tax-break',       
      icon: 'hand-holding-dollar' , 
      open: false
    },
    { 
      title: 'Testi documento', 
      url: '/document-text', 
      icon: 'align-justify' , 
      open: false
    },
    {
      title: 'Sconti', open: false, hide: false,
      children: [
        { title: 'Offerte/Promozioni', url: '/discount-campaigns', icon: 'tags' },
        { title: 'Listini Scontistiche', url: '/discount-lists', icon: 'hand-holding-dollar' },
        // { title: 'Listini per Agenti', url: '/discounts', icon: 'list' },
      ]
    },
    {
      title: 'Clienti', open: false, hide: false,
      children: [
        { title: 'Aziende', url: '/companies', icon: 'building' },
        { title: 'Leads', url: '/leads/lead', icon: 'users-viewfinder' },
        { title: 'Contatti', url: '/leads/contact', icon: 'address-book' }
      ]
    },
    { 
      title: 'Fornitori', 
      url: '/supplier', 
      icon: 'align-justify' , 
      open: false
    },
    // {
    //   title: 'Notifiche', open: false, hide: false,
    //   children: [
    //     { title: 'Notifiche lette', url: '/readNotifications', icon: 'envelope-open' },
    //     { title: 'Notifiche non lette', url: '/unreadNotifications', icon: 'envelope' }
    //   ]
    // },
    // {
    //   title: 'Contratti', open: false, hide: false,
    //   children: [
    //     { title: 'Documenti Accessori', url: '/docs-order-required', icon: 'file-contract' },
    //   ]
    // },
    // {
    //   title: 'Budget', open: false, hide: false,
    //   children: [
    //     // { title: 'Riepilogo', url: '/budget-summary', icon: 'list' },
    //     { title: 'Divisione per Agente', url: '/budget-split', icon: 'list' },
    //   ]
    // },
    {
      title: 'Impostazioni', open: false, hide: false,

      children: [
        { title: 'Impostazioni Generali', url: '/settings', icon: 'gears' },
        { title: 'Tipologia di Negozio', url: '/shop-types', icon: 'gears' },
        { title: 'Utenti', url: '/users', icon: 'users' },
        { title: 'Ruoli', url: '/roles', icon: 'users-gear' },
        { title: 'Permessi Utenti', url: '/user-role-permissions', icon: 'users-rays' },
        { title: 'Aree di Competenza', url: '/expertise-areas', icon: 'square' },
        { title: 'Condizioni di pagamento', url: '/payments-conditions', icon: 'cart-shopping' },
        { title: 'Provvigioni', url: '/commissions', icon: 'sack-dollar' },
        { title: 'Tabelle Provvigioni-Sconti', url: '/discount-matches', icon: 'percent' },
      ]

    }
  ];


  constructor(
    private router: Router,
    public authenticationService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private userRolePermissionsService: UserRolePermissionsService,
    private menuCtrl: MenuController
  ) {
  }

  ngOnInit() {
    this.items = this.itemsNavbar;
    this.checkSettings();
  }

  async checkSettings() {
    console.log("checkSettings")

    if (this.authenticationService.currentUserValue) {

      let userCanEnterSettings = this.authenticationService.currentUserValue.can_enter_settings;

      console.log("userCanEnterSettings", userCanEnterSettings)

      let indexSettings = this.itemsNavbar.findIndex(x => x.title == 'Impostazioni');

      console.log("indexSettings", indexSettings)


      if (!userCanEnterSettings && !(this.authenticationService.currentUserValue.role == UserRoleTypeEnum.ADMIN)) {

        this.itemsNavbar[indexSettings].hide = true;

      }
      else {

        this.itemsNavbar[indexSettings].hide = false;
      }

    }




  }

  logout() {
    console.log("logout")
    this.authenticationService.logout();
  }

  openSubMenu(index:number){

    for(var i=0; i < this.items.length; i++) {

      if(i==index) {

        this.items[i].open = !this.items[i].open;

      }
      else {

        this.items[i].open = false;

      }
    }

  }
}

import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticationService } from '../services/authentication.service';
import { ToastController } from '@ionic/angular';
import { TokenService } from '../services/token.service';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(
        private router: Router,
        private tokenService: TokenService,
        private authService: AuthenticationService,
        public toastController: ToastController) { }
    
    
      intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError( err => {
    
          console.error(err);
          console.error(err.status);

          // if ([401, 403].includes(err.status) && this.authService.currentUser) {
          //   // auto logout if 401 or 403 response returned from api
          //   // this.authService.logout();
          // }
    
          const error = (err && err.error && err.error.message) || (err && err.error && err.error.messages) || err.statusText;
    
          // console.log(err && err.error && err.error.messages);
    
          let errorText = error;
    
          if(Array.isArray(error)){
            errorText = error.join('<br>');
          }
    
          //console.error(errorText);
    
          if(!request.url.includes('/auth/') && ![401, 403].includes(err.status)){     
    
            this.toastController.create({
              message: errorText,
              duration: 10000,
              cssClass: 'toast-error',
              buttons: [          
                {
                  text: 'Dismiss',
                  role: 'cancel',
                  handler: () => { 
    
                  }
                }
              ]
            }).then(toast => {
              toast.present();
            })
    
          }
          else if(request.url.includes('/auth/refresh-token') && [401, 403].includes(err.status)) {

            this.authService.logout()

          }
    
          return throwError(err);
        }))
      }
}
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { User } from '../../models/user.class';
import { UserPosition } from '../../models/user_position.class';


const httpOptions = {
    headers: new HttpHeaders().set( 'Content-Type', 'application/json' )
  };

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private http: HttpClient) { }

  getAll() {
    console.log("getAllUsers")
    return this.http.get<User[]>(`${environment.apiUrl}/api/${environment.apiVersion}/user`, httpOptions)
  }

  getById(id: string) {
    console.log("getByIdUsers")
    return this.http.get<User>(`${environment.apiUrl}/api/${environment.apiVersion}/user/` + id, httpOptions)
  }

  create(body) {
    console.log("createUsers")
    return this.http.post<User>(`${environment.apiUrl}/api/${environment.apiVersion}/auth/sign-up`, body, httpOptions)
  }

  update(body, id: string) {
    console.log("updateUsers")
    return this.http.put<User>(`${environment.apiUrl}/api/${environment.apiVersion}/user/` + id, body, httpOptions)
  }

  delete(id: string) {
    console.log("deleteUsers")
    return this.http.delete<User>(`${environment.apiUrl}/api/${environment.apiVersion}/user/` + id, httpOptions)
  }

  getUserPositions(id: string) {
    console.log("getUserPositions")
    return this.http.get<UserPosition[]>(`${environment.apiUrl}/api/${environment.apiVersion}/user/position/` + id, httpOptions)
  }

  getPositions() {
    console.log("getPositions")
    return this.http.get<UserPosition[]>(`${environment.apiUrl}/api/${environment.apiVersion}/user/positions/all`, httpOptions)
  }

  getAgents() {
    console.log("getAgents")
    return this.http.get<User[]>(`${environment.apiUrl}/api/${environment.apiVersion}/user/agent`, httpOptions)
  }
}
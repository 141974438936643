import { Base } from "./base.class";
import { PriceList } from "./price_list.class";
import { Product } from "./product.class";

export class ProductPriceList extends Base{
    crm_id: string;
    product: Product;
    product_id: string;
    price: number;
    price_list: PriceList;
    price_list_id: string;
}
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
const routes: Routes = [
  // { 
  //   path: '**',
  //   redirectTo: 'login',
  //   pathMatch: 'full',
  // },
  // { 
  //   path: '**',
  //   redirectTo: 'login',
  //   pathMatch: 'full',
  // },
  // { path: '**',loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule), canActivate: [AuthGuard] },
  {
    path: 'login',
    loadChildren: () => import('./pages/auth/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'logout',
    loadChildren: () => import('./pages/auth/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: '',
    redirectTo: 'news',//'budget-summary',
    pathMatch: 'full',
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'recovery',
    loadChildren: () => import('./pages/auth/recovery/recovery.module').then( m => m.RecoveryPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'recovery/:token',
    loadChildren: () => import('./pages/auth/recovery/recovery.module').then( m => m.RecoveryPageModule)
  },
  {
    path: 'user-role-permissions',
    loadChildren: () => import('./pages/user-role-permissions/user-role-permissions.module').then( m => m.UserRolePermissionsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path:'user-role-permissions/add',
    loadChildren: () => import('./pages/user-role-permissions/user-role-permission-detail/user-role-permission-detail.module').then( m => m.UserRolePermissionDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path:'user-role-permissions/edit/:id',
    loadChildren: () => import('./pages/user-role-permissions/user-role-permission-detail/user-role-permission-detail.module').then( m => m.UserRolePermissionDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'users',
    loadChildren: () => import('./pages/users/users.module').then( m => m.UsersPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'users/add',
    loadChildren: () => import('./pages/users/user-detail/user-detail.module').then( m => m.UserDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path:'users/edit/:id',
    loadChildren: () => import('./pages/users/user-detail/user-detail.module').then( m => m.UserDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then( m => m.SettingsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'leads/:status',
    loadChildren: () => import('./pages/leads/leads.module').then( m => m.LeadsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'products',
    loadChildren: () => import('./pages/products/products.module').then( m => m.ProductsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'products/add',
    loadChildren: () => import('./pages/products/product-detail/product-detail.module').then( m => m.ProductDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path:'products/edit/:id',
    loadChildren: () => import('./pages/products/product-detail/product-detail.module').then( m => m.ProductDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'categories',
    loadChildren: () => import('./pages/categories/categories.module').then( m => m.CategoriesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path:'categories/add',
    loadChildren: () => import('./pages/categories/category-detail/category-detail.module').then( m => m.CategoryDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path:'categories/edit/:id',
    loadChildren: () => import('./pages/categories/category-detail/category-detail.module').then( m => m.CategoryDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'brands',
    loadChildren: () => import('./pages/brands/brands.module').then( m => m.BrandsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path:'brands/add',
    loadChildren: () => import('./pages/brands/brand-detail/brand-detail.module').then( m => m.BrandDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path:'brands/edit/:id',
    loadChildren: () => import('./pages/brands/brand-detail/brand-detail.module').then( m => m.BrandDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'companies',
    loadChildren: () => import('./pages/companies/companies.module').then( m => m.CompaniesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'deductibles',
    loadChildren: () => import('./pages/deductibles/deductibles.module').then( m => m.DeductiblesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'deductibles/add',
    loadChildren: () => import('./pages/deductibles/deductible-detail/deductible-detail.module').then( m => m.DeductibleDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'deductibles/edit/:id',
    loadChildren: () => import('./pages/deductibles/deductible-detail/deductible-detail.module').then( m => m.DeductibleDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'payments-conditions',
    loadChildren: () => import('./pages/payments-conditions/payments-conditions.module').then( m => m.PaymentsConditionsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'discount-matches',
    loadChildren: () => import('./pages/discount-match/discount-match.module').then( m => m.DiscountMatchPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'commissions',
    loadChildren: () => import('./pages/commissions/commissions.module').then( m => m.CommissionsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'product-accessories',
    loadChildren: () => import('./pages/product-accessories/product-accessories.module').then( m => m.ProductAccessoriesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'discount-campaigns',
    loadChildren: () => import('./pages/discount-campaigns/discount-campaigns.module').then( m => m.DiscountCampaignsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'expertise-areas',
    loadChildren: () => import('./pages/expertise-areas/expertise-areas.module').then( m => m.ExpertiseAreasPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'roles',
    loadChildren: () => import('./pages/roles/roles.module').then( m => m.RolesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'news-categories',
    loadChildren: () => import('./pages/news-categories/news-categories.module').then( m => m.NewsCategoriesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'news',
    loadChildren: () => import('./pages/news/news.module').then( m => m.NewsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'discount-lists',
    loadChildren: () => import('./pages/discount-lists/discount-lists.module').then( m => m.DiscountListsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'discounts',
    loadChildren: () => import('./pages/discounts/discounts.module').then( m => m.DiscountsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'commercial-goals',
    loadChildren: () => import('./pages/commercial-goals/commercial-goals.module').then( m => m.CommercialGoalsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'documents',
    loadChildren: () => import('./pages/documents/documents.module').then( m => m.DocumentsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'price-lists',
    loadChildren: () => import('./pages/price-lists/price-lists.module').then( m => m.PriceListsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'budget-split',
    loadChildren: () => import('./pages/budget-split/budget-split.module').then( m => m.BudgetSplitPageModule),
    canActivate: [AuthGuard]
  },
  // {
  //   path: 'budget-summary',
  //   loadChildren: () => import('./pages/budget-summary/budget-summary.module').then( m => m.BudgetSummaryPageModule),
  //   canActivate: [AuthGuard]
  // },
  {
    path: 'vat',
    loadChildren: () => import('./pages/vat/vat.module').then( m => m.VatPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'docs-order-required',
    loadChildren: () => import('./pages/docs-order-required/docs-order-required.module').then( m => m.DocsOrderRequiredPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'agents',
    loadChildren: () => import('./pages/agents/agents.module').then( m => m.AgentsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'shop-types',
    loadChildren: () => import('./pages/shop-types/shop-types.module').then( m => m.ShopTypesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'tax-break',
    loadChildren: () => import('./pages/tax-break/tax-break.module').then( m => m.TaxBreakPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'supplier',
    loadChildren: () => import('./pages/supplier/supplier.module').then( m => m.SupplierPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'document-text',
    loadChildren: () => import('./pages/document-text/document-text.module').then( m => m.DocumentTextPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'supplier',
    loadChildren: () => import('./pages/supplier/supplier.module').then( m => m.SupplierPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'fees',
    loadChildren: () => import('./pages/fees/fees.module').then( m => m.FeesPageModule),
    canActivate: [AuthGuard]
  },
  { 
    path: '**',
    redirectTo: 'login',
    pathMatch: 'full',
  },

  







];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { first, map, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { TokenService } from './token.service';
import { LoadingController } from '@ionic/angular';
import { UsersService } from '../pages/users/users.service';
import { UserRoleTypeEnum } from '../models/user.class';
// import { RolesService } from '../pages/roles/roles.service';


const httpOptions = {
    headers: new HttpHeaders().set('Content-Type', 'application/json')
};

const options = {
    headers: new HttpHeaders().set('Content-Type', 'application/json'),
    params: new HttpParams()
};


@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    private readonly CURRENT_USER = "USER"

    public currentUserSubject: BehaviorSubject<any | null>;
    public currentUser: Observable<any | null>;


    constructor(
        private http: HttpClient,
        private router: Router,
        private tokenService: TokenService,
        private loadingController: LoadingController,
        // private rolePermissionsService: RolesService,
    ) {

        this.currentUserSubject = new BehaviorSubject<any | null>(null);
        this.currentUser = this.currentUserSubject.asObservable()
    }

    public get currentUserValue(): any | null {
        let storedUser = localStorage.getItem(this.CURRENT_USER)

        this.currentUserSubject.next(JSON.parse(storedUser))

        return this.currentUserSubject.value
    }

    public set currentUserValue(user: any | null) {
        this.currentUserSubject.next(user);


        if (user === null) {
            localStorage.removeItem(this.CURRENT_USER)
        }
        else {
            localStorage.setItem(this.CURRENT_USER, JSON.stringify(user));
        }

    }

    login(email: string, password: string) {
        return this.http.post<any>(`${environment.apiUrl}/api/${environment.apiVersion}/auth/sign-in`, { email: email, password: password }, httpOptions)
            .pipe(switchMap(async (tokens: { user, accessToken, refreshToken }) => {
                console.log(tokens)
                // store user details and jwt token in local storage to keep user logged in between page refreshes

                this.currentUserValue = tokens.user

                this.tokenService.saveToken(tokens.accessToken)

                this.tokenService.saveRefreshToken(tokens.refreshToken)

                // this.tokenService.startRefreshTokenTimer();

                const userCanLoginDashboard = this.currentUserValue.can_login_dashboard;

                if(!userCanLoginDashboard && !(this.currentUserValue.role == UserRoleTypeEnum.ADMIN)) {

                    // this.tokenService.stopRefreshTokenTimer();

                    this.currentUserValue = null;

                    localStorage.clear()

                    this.router.navigate(['/login']);

                    return Promise.reject();

                }

                return from(Promise.all([]));
            }));
    }

    async logout() {
        // remove user from local storage to log user out
        // let token = this.tokenService.getToken();
        let token = this.tokenService.getRefreshToken();

        if (token) {

            const loading = await this.loadingController.create();
    
            await loading.present();

            await this.http.delete(`${environment.apiUrl}/api/${environment.apiVersion}/auth/logout/${token}`)
                .toPromise().then(async () => {
                    await loading.dismiss();

                    // this.tokenService.stopRefreshTokenTimer();

                    this.currentUserValue = null;

                    localStorage.clear()

                    this.router.navigate(['/login']);

                    // location.reload()
                })
        }

    }

    requestRecovery(email: string) {

        return this.http.post<any>(`${environment.apiUrl}/api/${environment.apiVersion}/auth/request-recovery`, { email });

    }

    setNewPassword(recoveryToken: string, password: string) {

        return this.http.post<any>(`${environment.apiUrl}/api/${environment.apiVersion}/auth/recovery/${recoveryToken}`, { password });

    }

    isInRole(rolesAllowed: Array<any>) {

        if (this.currentUserValue && rolesAllowed.length == 0) return true;

        let result = this.currentUserValue && rolesAllowed.includes(this.currentUserValue.role);

        return result;
    }
}

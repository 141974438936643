import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  private readonly JWT_TOKEN = "JWT_TOKEN";
  private readonly REFRESH_TOKEN = "REFRESH_TOKEN";

  constructor(private http: HttpClient) { }


  getToken(): string | null {
    return localStorage.getItem(this.JWT_TOKEN);
  }

  saveToken(token: string) {
    localStorage.setItem(this.JWT_TOKEN, token);
  }

  deleteToken() {
    localStorage.removeItem(this.JWT_TOKEN);
  }


  public getRefreshToken(): string | null {
    return localStorage.getItem(this.REFRESH_TOKEN);
  }

  saveRefreshToken(refreshToken: string) {
    localStorage.setItem(this.REFRESH_TOKEN, refreshToken);
  }

  private deleteRefreshToken() {
    localStorage.removeItem(this.REFRESH_TOKEN);
  }


  sendRefreshTokenRequest(): Observable<any> {

    // console.log("sendRefreshTokenRequest");
    
    let refreshToken = this.getRefreshToken();
    // console.log("refreshToken",refreshToken);

    if(!refreshToken){
      console.log("refresh token missing");
      
      return of(null);
    }
    

    return this.http.post<any>(`${environment.api_site}/auth/refresh-token`, {
      refreshToken
    })
      .pipe(
        tap((resp) => {

          console.log("TokenService sendRefreshTokenRequest response", resp);
          

          console.log("tokens", resp);
          this.saveToken(resp.accessToken);
          this.saveRefreshToken(resp.refreshToken);

        }),
        catchError((error) => {

          console.error("sendRefreshToken ERROR", error);
          

          this.deleteRefreshToken();
          this.deleteToken();

          //this.authService.logout();

          return of(null);
        })
      );

  }


  getTokenExpirationDate(token: string): Date | null {

    let decoded = jwt_decode<any>(token);

    if (decoded.exp === undefined) return null;

    const date = new Date(0);

    date.setUTCSeconds(decoded.exp);

    return date;

  }

  isTokenValid(): boolean {

    let token = this.getToken();

    if (!token) {
      return false;
    }

    const date = this.getTokenExpirationDate(token);

    if (date == null) return false;

    let isValid = date.valueOf() > new Date().valueOf();

    return isValid;

  }

  // private refreshTokenTimeout;

  // public startRefreshTokenTimer() {
  //   // parse json object from base64 encoded jwt token
  //   const jwtToken = jwt_decode<any>(this.getToken());

  //   // set a timeout to refresh the token a minute before it expires
  //   const expires = new Date(jwtToken.exp * 1000);
  //   const timeout = expires.getTime() - Date.now() - (60 * 1000);
  //   this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
  // }

  // public stopRefreshTokenTimer() {
  //   clearTimeout(this.refreshTokenTimeout);
  // }


}


import { Component, Input, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { Fee } from '../../models/fee.class';
import { Product } from '../../models/product.class';

@Component({
  selector: 'app-product-fees-modal',
  templateUrl: './product-fees-modal.component.html',
  styleUrls: ['./product-fees-modal.component.scss'],
})
export class ProductFeesModalComponent implements OnInit {

  // @Input()
  // product: Product;

  // fees: Fee[] = [];

  // @Input()
  // year: number;
  // @Input()
  // value: number;
  // @Input()
  // qta: number;

  // @Input()
  // name: string;

  // @Input()
  // description: string;

  // @Input()
  // default : boolean

  // fee_index: number = -1;

  constructor(
    private modalCtrl: ModalController,
    private alertController: AlertController,
  ) {

  }

  ngOnInit() {
  //   this.fees = this.product.fees?.length > 0 ? this.product.fees : [];

  //   this.year = null;
  //   this.qta = null;
  //   this.value = null;
  //   this.description = null;
  //   this.name = null;
  //   this.default = null
  //   // console.log(this.fees, this.product)
  // }

  // cancel() {
  //   this.modalCtrl.dismiss(null, 'cancel');
  // }

  // confirm() {
  //   this.modalCtrl.dismiss({ fees: this.fees }, 'confirm');
  // }

  // add() {
  //   let newFee: Fee = new Fee();

  //   console.log(newFee);

  //   if (this.description == null) {
  //     this.showError('Attenzione', 'Inserire la descrizione')
  //   }
  //   else {

  //     newFee.qta = this.qta;
  //     newFee.year = this.year;
  //     newFee.value = this.value;
  //     newFee.description = this.description;
  //     newFee.name = this.name;
  //     newFee.default = this.default

  //     // console.log(this.fees, this.fees.findIndex(x => x.year == newFee.year && x.qta == newFee.qta));

  //     if (this.fees.findIndex(x => x.default == newFee.default) == -1) {

  //     this.fees.push(newFee);

  //     this.year = null;
  //     this.qta = null;
  //     this.value = null;
  //     this.description = null;
  //     this.name = null;
  //     this.default = null
  //     this.fee_index = -1;

  //     }
  //     else {
  //       this.showError('Attenzione', 'Il canone default è già presente nella lista.')
  //     }

  //   }

  }

  // update(i: number) {

  //   if (this.description == null) {
  //     this.showError('Attenzione', 'Inserire la descrizione')
  //   }
  //   else {
  //     // console.log(this.fee_index, this.fees, this.fees.findIndex(x => x.year == this.year && x.qta == this.qta));

  //     if (this.fees.findIndex(x => x.year == this.year && x.qta == this.qta) == this.fee_index || this.fees.findIndex(x => x.year == this.year && x.qta == this.qta) == -1) {

  //       this.fees[i].qta = this.qta;
  //       this.fees[i].year = this.year;
  //       this.fees[i].value = this.value;
  //       this.fees[i].name = this.name;
  //       this.fees[i].description = this.description;

  //       this.fees[i].default = this.default;

  //       this.year = null;
  //       this.qta = null;
  //       this.value = null;
  //       this.description = null;
  //       this.name = null
  //       this.default = null

  //       this.fee_index = -1;

  //     }
  //     else {
  //       this.showError('Attenzione', 'Il canone relativo all\'anno inserito è già presente nella lista.')
  //     }
  //   }


  // }

  // remove(i: number) {

  //   this.fees.splice(i, 1)

  //   this.year = null;
  //   this.qta = null;
  //   this.value = null;
  //   this.name = null
  //   this.description = null;
  //   this.default = null

  //   this.fee_index = -1;


  // }

  // UpdateOrDelete(i: number) {

  //   console.log(i, this.fees);


  //   this.year = this.fees[i].year;
  //   this.qta = this.fees[i].qta;
  //   this.value = this.fees[i].value;
  //   this.name = this.fees[i].name;
  //   this.description = this.fees[i].description;
  //   this.default = this.fees[i].default

  //   this.fee_index = i;

  //   // console.log(this.fee_index);
    
  // }

  // async showError(title, msg) {

  //   const alert = await this.alertController.create({
  //     header: title,
  //     message: msg,
  //     buttons: ['OK'],
  //   });

  //   await alert.present();

  // }
}
